.chat-bubble {
  background-color: #e6f8f1;
  padding: 16px 28px;
  -webkit-border-radius: 20px;
  -webkit-border-bottom-left-radius: 2px;
  -moz-border-radius: 20px;
  -moz-border-radius-bottomleft: 2px;
  border-radius: 20px;
  border-bottom-left-radius: 2px;
  display: inline-block;
}
.typing {
  align-items: center;
  display: flex;
  height: 17px;
}
.typing .dot {
  animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
  background-color: #f5f5ff;
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

pre {
  text-align: justify !important;
  /* font-family: Inter, sans-serif !important; */
  font-size: 17px !important;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  white-space: pre-wrap;
  margin-bottom: 0rem !important;
}

.chat__conversation-panel {
  border-radius: 12px;
  padding: 0 1em;
  height: 55px;
  margin: 0.5em 0 0;
  border: 1px solid #ccd2fb;
  background: #fff;
  box-shadow: 0px 20px 25px -1px rgba(0, 0, 0, 0.06);
}
.chat__conversation-panel__container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}
.chat__conversation-panel__container .panel-item:not(:last-child) {
  margin: 0 1em 0 0;
}
.chat__conversation-panel__button {
  background: grey;
  height: 20px;
  width: 30px;
  border: 0;
  padding: 0;
  outline: none;
  cursor: pointer;
}
.chat__conversation-panel .emoji-button svg {
  width: 100%;
  fill: transparent;
  stroke: #54575c;
}
.chat__conversation-panel .send-message-button {
  background: var(--chat-send-button-background);
  height: 30px;
  min-width: 30px;
  border-radius: 50%;
  transition: 0.3s ease;
}
.chat__conversation-panel .send-message-button:active {
  transform: scale(0.97);
}
.chat__conversation-panel .send-message-button svg {
  margin: 1px -1px;
}
.chat__conversation-panel__input {
  width: 100%;
  height: 100%;
  outline: none;
  position: relative;
  color: var(--chat-text-color);
  font-size: 18px;
  background: transparent;
  border: 0;
  /* font-family: "Inter", sans-serif; */
  resize: none;
}
.btn-icon {
  position: relative;
  cursor: pointer;
}

#chat-messages::-webkit-scrollbar {
  width: 0px;
}

#chat-messages::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.2);
}

#chat-messages::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.1);
}

ul,
li {
  list-style-type: none;
}
.wrapper {
  padding-top: 15px;
  padding-bottom: 15px;
  /* border: 1px solid grey; */
  border: 1px solid rgb(206, 200, 200);

  border-radius: 10px;
}

.wrapper ul {
  width: 100%;
  margin: 0 auto;
  overflow-x: hidden;
  /* overflow-y: auto; */
  overflow-y: auto;
  padding-right: 30px;
}

.wrapper ul li {
  margin-bottom: 20px;
  padding: 10px 20px;
  background-color: rgba(255, 255, 255, 0.65);
  position: relative;
  border-radius: 10px;
  font: 1em/1.6 "arial";
  clear: both;
}
.wrapper ul li:nth-child(odd) {
  /* font-family: "Inter"; */
  /* font-size: 17px; */
  float: right;
  margin-left: 80px;
  color: #000;
  background-color: #cdf5fd;
}

.wrapper ul li:nth-child(even) {
  float: left;
  margin-right: 80px;
  color: #141944;
  background-color: #f5f5ff;
}

.wrapper ul li:before,
.wrapper ul li:after {
  position: absolute;
  content: "";
}

.wrapper ul li:before {
  width: 45px;
  height: 45px;
  top: 0;
  border-radius: 50%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.wrapper ul li:after {
  width: 0;
  height: 0;
  top: 15px;
  border-top: 15px solid rgba(255, 255, 255, 0.65);
}

.wrapper ul li:nth-child(odd):after {
  left: -15px;
  border-left: 15px solid transparent;
}

.wrapper ul li:nth-child(even):after {
  right: -15px;
  border-top-color: rgba(255, 255, 255, 0.3);
  border-right: 15px solid transparent;
}

@media only screen and (min-width: 768px) {
  .wrapper ul {
    width: 100%;
    height: 600px;
  }
}
@media only screen and (max-width: 767px) {
  .wrapper ul {
    width: 90%;
    height: 350px;
  }
  .conImg {
    display: none;
  }
}
